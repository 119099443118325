import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cbbfdc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "w-100 d-flex justify-content-center",
  style: {"padding-top":"60px"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "text-center header"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center header"
}
const _hoisted_6 = {
  key: 2,
  class: "gray mb-0 text-center"
}
const _hoisted_7 = {
  key: 3,
  class: "gray mb-0 text-center"
}
const _hoisted_8 = {
  key: 4,
  class: "gray mb-0 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_el = _resolveComponent("button-el")!
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    btnLabel: _ctx.$t('dashboard-start-spring'),
    "modal-wrapper-class": " code-inputs",
    "modal-size": "lg"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!$props.status)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/images/retry.png'),
              alt: ""
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        ($props.status)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: require('@/assets/images/check.png'),
              alt: ""
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ])
    ]),
    body: _withCtx(() => [
      (!$props.title)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.$t("dashboard-verf-success-title")), 1))
        : _createCommentVNode("", true),
      ($props.title)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString($props.title), 1))
        : _createCommentVNode("", true),
      (!$props.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("dashboard-verf-success-desc")), 1))
        : _createCommentVNode("", true),
      ($props.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($props.description), 1))
        : _createCommentVNode("", true),
      ($props.autoClose)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createTextVNode(_toDisplayString(Math.round($setup.autoCloseIntervalValue / 5)) + " saniye içerisinde otomatik kapanacak. ", 1),
            _createElementVNode("div", {
              class: "auto-closebar",
              style: _normalizeStyle({width: ($setup.autoCloseIntervalValue / 30  )* 100    + '%'})
            }, null, 4)
          ]))
        : _createCommentVNode("", true),
      ($props.showConfirmButton)
        ? (_openBlock(), _createBlock(_component_button_el, {
            key: 5,
            type: "button",
            label: _ctx.$t('continue-button'),
            icon: "angles-right",
            "btn-class": "bt-danger",
            onClick: _cache[0] || (_cache[0] = () => {})
          }, null, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["btnLabel"]))
}