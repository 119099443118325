<template>
  <div class="social-wrapper">
    <!-- <button v-for="(value) in socialIcons" class="social-list-icon"
      :style="{ display: socialIcons.includes(value) && $props.links[value] !== null ? 'block' : 'none' }" :class="btClass"
      @click="handleRoute(value)">
      <i :class="`gray fa-brands fa-${value}`"></i>
    </button> -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "SocialList",
  data: () => ({
    socialIcons: [
      "facebook",
      "instagram",
      "twitter",
      "youtube",
      "linkedin",
    ],
  }),
  setup(props) {
    const router = useRouter();
    const handleRoute = (link) => {
      window.open(props.links[link])
    }
    return { handleRoute };
  },
  props: {
    btClass: {
      type: String,
      required: false,
    },
    links: {
      required: true
    }
  },
};
</script>

<style lang="scss">
.social-wrapper {
  display: flex;
  gap: 10px;

  .social-list-icon {
    width: 50px;
    height: 50px;
    border: 1px solid $light;
    background: #fbfbfb;
    transition: $trns;

    &.small {
      width: 35px;
      height: 35px;
    }

    &.light {
      background: $light;
      font-size: 12px;
    }

    &:hover {
      background: $secondary;
      border-color: transparent;

      svg {
        color: $white;
        transition: $trns;
      }
    }

    svg {
      color: $gray;
      transition: $trns;
    }
  }
}
</style>
